import { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { server } from "../common";
import { toast } from "react-toastify";

const DownLoad = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  // Get
  const getData = () => {
    server
      .get("/download/getalldownload", {
        headers: {
          "Content-Type": "application/json",
          // "auth-token": user.authToken,
        },
      })
      .then(function (response) {
        console.log("api response", response.data);
        if (response.status === 200 || response.status === 201) {
          setData(response.data);
        }
      })
      .catch(function (error) {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else if (error.response?.data?.error) {
          toast.error(error.response.data.error);
        } else toast.error("Failed to connect to server");
      });
  };

  return (
    <>
      {/* <div className="p-2 mt-3" style={{ backgroundColor: "#FFA27F" }}>
        <h3 className="container fw-bold align-items-center justify-content-center text-center">
          Download
        </h3>
      </div> */}
      <div className="container mt-4 offset-lg-12">
        <div className="card">
          <div className="card-header">{t("Important Files")}</div>
          <div className="card-body">
            <table className="table table-bordered table-striped ">
              <tbody>
                {data.map((item, index) => {
                  return (
                    <>
                      <tr>
                        <td>{index + 1}</td>
                        <td> {item.title}</td>
                        <td>
                          <button className="button btn btn-sm">
                            <a
                              className="text-decoration-none text-white"
                              href={item.pdf}
                              target="_blank"
                            >
                              Download
                            </a>
                          </button>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default DownLoad;
