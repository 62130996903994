import React from "react";
import { Container, Card, Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const TermsAndConditions = ({ handleClose, show }) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        // backdrop="static"
        // keyboard={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="text-center mb-4"> {t("TERMS AND CONDITIONS")}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="">
            <div className="">
              <ol style={{ textAlign: "justify" }}>
                <li>
                  {t(
                    "The design must be original. Plagiarism will not be allowed and design should not be in violation of copyright Acts in the country of origin or the Indian Copyright Act, 1957 or the Intellectual Property Rights of any third party."
                  )}
                </li>
                <li>
                  {t(
                    "By agreeing to submit, an applicant hereby warrants that he or she is the original designer/creator of the design. Further, that the design submitted by the applicant or any constituent part of it is not the intellectual property of any third party. The applicant also understands that in case the submitted design is found to be the intellectual property of any third party, his/her application will stand rejected, and the Nashik Municipal Corporation (NMC) will not be responsible for any infringement whatsoever. Also, NMC will not indemnify any claim by a third party in connection with infringement of Intellectual Rights related to design submitted by an applicant."
                  )}
                </li>
                <li>
                  {t(
                    "In case of any copyright issues, the applicant will be responsible to settle any legal proceedings arising out of it at his/her end."
                  )}
                </li>
                <li>
                  {t(
                    "All entries are governed by the provisions of Emblems and Names (Prevention of improper use) Act, 1950 and any violation of the said Act will result in disqualification."
                  )}
                </li>
                <li>
                  {t(
                    "The design must not contain any provocative, objectionable, or inappropriate content."
                  )}
                </li>
                <li>
                  {t(
                    "Winning entries shall be required to provide the original open-source file of the design. The winning designs would be the intellectual property of the NMC, and the winner shall not exercise any right over it."
                  )}
                </li>
                <li>
                  {t(
                    "NMC will have unfettered right to modify any design / add / delete any info / design feature in any form to it with due credit given to the original designer."
                  )}
                </li>
                <li>
                  {t(
                    "The winning design is meant to be used by NMC for promotional and display purposes, information, education, and communication materials and for any other use as may be deemed appropriate."
                  )}
                </li>
                <li>
                  {t(
                    "There will be no notification to applicants of rejected entries."
                  )}
                </li>
                <li>
                  {t(
                    "NMC reserves the right to cancel or amend all or any part of this competition and / or terms and conditions / technical parameters / evaluation criteria. However, any changes to the terms and conditions / technical parameters / evaluation criteria, or the cancellation of the competition, will be updated / posted on the platform through which initial communication of competition is made. It would be the responsibility of the participant to keep themselves informed of any changes in the terms and conditions /technical / parameters / evaluation criteria stated for this competition."
                  )}
                </li>
                <li>
                  {t(
                    "The responsibility to comply with the submission of entries, technical criteria and selection process fully lies with the participants and NMC shall not be answerable to any dispute raised by a third party."
                  )}
                </li>
                <li>
                  {t(
                    "The winner will be declared through email. NMC may announce the winner’s name on its social media pages and may also upload details on the official website of the corporation."
                  )}
                </li>
                <li>
                  {t(
                    "NMC reserves rights to reject any proposal. A proposal will be excluded from participation if :"
                  )}
                  <ul>
                    <li>{t("it is not sent within the deadline")}</li>
                    <li>
                      {t(
                        "documentation does not comply with the requirements."
                      )}
                    </li>
                    <li>
                      {t(
                        "This is an anonymous competition, and the unique identity code is the only means of identification. The name of participants should neither appear on the submission nor as part of the file names."
                      )}
                    </li>
                    <li>
                      {t(
                        "Participants who try to contact members of the jury will be disqualified."
                      )}
                    </li>
                    <li>
                      {t(
                        "An individual is found to be participating in multiple proposals."
                      )}
                    </li>
                  </ul>
                </li>
                <li>{t("Proposals can be submitted individually.")}</li>
              </ol>
            </div>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TermsAndConditions;
