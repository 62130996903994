import { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import "./slider.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "../form/Form";

function Slider() {
  const formRef = useRef(null); // Reference for the form in the child component
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div
        id="carouselExampleCaptions"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators mt-5">
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          {/* Add more buttons for additional slides */}
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="/slider4.png"
              className="d-block w-100 h-100"
              alt="Descriptive Alt Text"
            />
            <div className="carousel-caption">
              <button
                onClick={handleShow}
                className="btn btn-light participate-btn border border-white rounded-5 fw-bold btn-sm p-2"
              >
                Submit Your Proposal
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Body>
          <Form />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Slider;

{
  /* <h2>आपली गोदावरी | Our Godavari</h2>
                <h4>River Front Development and Design Competition</h4>
                <p>Last date of submission: 27.11.2024</p> */
}

{
  /* <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button> */
}
