import React from "react";
import "./homeabout.css";
import { useTranslation } from "react-i18next";

const HomeAbout = () => {
  const { t } = useTranslation();

  const prizes = [
    { rank: t("1"), text: t("st"), amount: t("₹ 5,00,000") },
    { rank: t("2"), text: t("nd"), amount: t("₹ 3,00,000") },
    { rank: t("3"), text: t("rd"), amount: t("₹ 2,00,000") },
  ];

  return (
    <>
      <div className="container ">
        <div className="col-md-12 text-center">
          <h3 className="fw-bold text-capitalize mt-3">
            {t("An exciting opportunity for everyone")}!!
          </h3>
          <p className="fs-5 mt-3" style={{ textAlign: "justify" }}>
            {t(
              "We are thrilled to invite everyone to participate in the competition to reimagine and transform the Godavari riverfront from Trimbakeshwar to Nashik. Competition presents an opportunity for creative minds to ideate on a sustainable, feasible, and innovative urban solution to restore and enhance the beauty and cultural significance of Godavari. We encourage participants to provide special attention to envision."
            )}
          </p>
        </div>

        <div className="text-center">
          <h4 className="my-4 text-uppercase">{t("Prizes")}</h4>

          <div className="row ">
            {prizes.map((prize, index) => (
              <div
                key={index}
                className="col-md-6 col-lg-4 justify-content-center align-items-center"
              >
                <div className="prize-card text-center my-2">
                  <div className="rank-circle fs-3 p-3">
                    <span>
                      {prize.rank}
                      <sup className="fs-5">{prize.text}</sup>
                    </span>
                  </div>
                  <div className="prize-amount ms-3 fs-2">{prize.amount}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeAbout;
