import { useContext, useState } from "react";
import { Row, Col } from "react-bootstrap";
import {
  Button,
  InputAdornment,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  CircularProgress,
} from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import GroupForm from "./GropForm";
import { server } from "../../common";
// import './form.css';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from "react-i18next";
import TermsAndConditions from "../../pages/TermsAndConditions";
import configContext from "../../configContext/configContext";
import Loader from "../../Loader";
const swalalert = withReactContent(Swal);

const initialValues = {
  fullname: "",
  email: "",
  mobile: "",
  occupation: "",
  // state: "",
  // nationality: "",
  short_idea: "",
  upload_proposal: [],
};

const IndividualForm = ({ formRef }) => {
  const [formType, setFormType] = useState("individual");
  const { t } = useTranslation();
  const [processing, setProcessing] = useState(false);
  const { setProgress } = useContext(configContext);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {
    values,
    handleBlur,
    touched,
    handleChange,
    handleSubmit,
    errors,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      fullname: Yup.string()
        .min(2, "Enter a valid Firstname")
        .max(25)
        .required("Please Enter Your Full Name"),
      email: Yup.string()
        .email("Enter a valid email")
        .required("Please Enter Your Email"),
      mobile: Yup.string()
        .min(10, "Enter valid Contact Number")
        .max(10, "Enter valid Contact Number")
        .required("Please Enter Your Contact Number"),
      occupation: Yup.string()
        .min(2, "Enter a valid occupation")
        .max(60)
        .required("Please Enter Your occupation"),
      // state: Yup.string()
      //   .min(2, "Enter a valid state")
      //   .max(60)
      //   .required("Please Enter Your state"),
      // nationality: Yup.string()
      //   .min(2, "Enter valid nationality")
      //   .max(60)
      //   .required("Please Enter Your nationality"),
      short_idea: Yup.string()
        .min(2, "Enter valid short idea")
        .test("maxWords", "Short idea must not exceed 500 words", (value) => {
          if (!value) return true; // Allow empty values
          const wordCount = value.trim().split(/\s+/).length; // Split by whitespace
          return wordCount <= 500; // Check if the word count is less than or equal to 500
        })
        .required("Please Enter Your short idea"),
      upload_proposal: Yup.mixed().required("Please Upload Proposal"),
      // .test("fileSize", "File size too large", (value) => {
      //   // return value ? value.size <= 200000000 : true; // 100MB limit
      // }),
    }),
    onSubmit: (values, { resetForm }) => {
      setProcessing(true); // Start showing loader

      // Validate images manually before submission
      if (values.upload_proposal.length === 0) {
        toast.error("Please select proposal files");
        setProcessing(false); // Stop showing loader
        return;
      }

      // Create FormData and append values
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        if (key === "upload_proposal") {
          // Append each file
          values.upload_proposal.forEach((file) =>
            formData.append("upload_proposal", file)
          );
        } else {
          formData.append(key, values[key]);
        }
      });

      swalalert
        .fire({
          title: "Are You Sure To Submit the Proposal?",
          text: `Confirm?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          buttonsStyling: true,
        })
        .then((swalObject) => {
          if (swalObject.isConfirmed) {
            setProgress(10); // Update progress if required

            server
              .post(`/individual/addindividual`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then(function (response) {
                if (response.status === 200 || response.status === 201) {
                  swalalert.fire({
                    title: "Success!",
                    text: `Proposal Submitted Successfully`,
                    icon: "success",
                  });
                  resetForm();
                  setFieldValue(null);
                }
              })
              .catch((error) => {
                toast.error(
                  error.response?.data?.message ||
                    error.response?.data?.error ||
                    "Failed to connect to server"
                );
              })
              .finally(() => {
                setProcessing(false); // Stop showing loader
                setProgress(100); // Update progress if required
              });
          } else {
            setProcessing(false); // Stop showing loader if not confirmed
          }
        });
    },
  });

  return (
    <div>
      <div>
        <Col md={12}>
          {/* Conditional Form Rendering */}

          <form onSubmit={handleSubmit}>
            <Row>
              <Col md={12}>
                <TextField
                  name="fullname"
                  margin="dense"
                  type="text"
                  placeholder={t("Applicant Full Name")}
                  variant="outlined"
                  label={t("Applicant Full Name")}
                  value={values.fullname}
                  onChange={handleChange}
                  fullWidth
                  required
                />
                {errors.fullname ? (
                  <p className="text-danger mb-0">{errors.fullname}</p>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <TextField
                  name="email"
                  margin="dense"
                  type="email"
                  placeholder={t("Applicant Email Id")}
                  variant="outlined"
                  label={t("Applicant Email Id")}
                  value={values.email}
                  onChange={handleChange}
                  fullWidth
                  required
                />
                {errors.email ? (
                  <p className="text-danger mb-0">{errors.email}</p>
                ) : null}
              </Col>
              <Col md={12}>
                <TextField
                  name="mobile"
                  margin="dense"
                  type="number"
                  placeholder={t("Contact Number")}
                  variant="outlined"
                  label={t("Contact Number")}
                  value={values.mobile}
                  onChange={handleChange}
                  fullWidth
                  required
                />
                {errors.mobile ? (
                  <p className="text-danger mb-0">{errors.mobile}</p>
                ) : null}
              </Col>
            </Row>
            <Col md={12}>
              <FormControl fullWidth className="mt-2 mb-2">
                <InputLabel id="demo-simple-select-label">
                  Occupation
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Occupation"
                  variant="outlined"
                  name="occupation"
                  required
                  value={values.purpose}
                  onChange={handleChange}
                >
                  <MenuItem value="Student">Student</MenuItem>
                  <MenuItem value="Professional">Professional</MenuItem>
                  <MenuItem value="Citizen">Citizen</MenuItem>
                </Select>
              </FormControl>
              {errors.occupation ? (
                <p className="text-danger mb-0">{errors.occupation}</p>
              ) : null}
            </Col>
            {/* <Col md={12}>
              <FormControl fullWidth required className="mt-2">
                <TextField
                  name="state"
                  margin="dense"
                  type="text"
                  placeholder="Enter State"
                  variant="outlined"
                  label=" State"
                  value={values.state}
                  onChange={handleChange}
                  fullWidth
                  required
                />
                {errors.state ? (
                  <p className="text-danger mb-0">{errors.state}</p>
                ) : null}
              </FormControl>
            </Col>
            <Col md={12}>
              <FormControl fullWidth required className="mt-2">
                <TextField
                  name="nationality"
                  margin="dense"
                  type="text"
                  placeholder="Enter Nationality"
                  variant="outlined"
                  label=" Nationality"
                  value={values.nationality}
                  onChange={handleChange}
                  fullWidth
                  required
                />
                {errors.nationality ? (
                  <p className="text-danger mb-0">{errors.nationality}</p>
                ) : null}
              </FormControl>
            </Col> */}
            <Row>
              <Col md={12}>
                <FormControl fullWidth required className="">
                  <TextField
                    name="short_idea"
                    margin="dense"
                    type="text"
                    placeholder={t("Enter Description")}
                    variant="outlined"
                    label={t("Short Description (500 words)")}
                    value={values.short_idea}
                    onChange={handleChange}
                    fullWidth
                    required
                    multiline
                    rows={4}
                  />
                  {errors.short_idea ? (
                    <p className="text-danger mb-0">{errors.short_idea}</p>
                  ) : null}
                </FormControl>
              </Col>

              <Col md={12}>
                <TextField
                  name="upload_proposal"
                  margin="dense"
                  type="file"
                  variant="outlined"
                  label={t(
                    "Upload Files (you can add upto 10 files) (jpg/png/pdf/PPT/Zip or Any File Type, Max 100MB)"
                  )}
                  className="mt-4"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" className="mt-5">
                        <label htmlFor="image"></label>
                      </InputAdornment>
                    ),
                    inputProps: {
                      multiple: true,
                      onChange: (event) => {
                        const files = Array.from(event.currentTarget.files); // Convert FileList to array
                        if (files.length > 10) {
                          toast.error(
                            "You can only upload a maximum of 10 files."
                          );
                          return; // Prevent setting more files if the limit is exceeded
                        }
                        setFieldValue("upload_proposal", files);
                      },
                    },
                  }}
                />
                {errors.upload_proposal ? (
                  <p className="text-danger mb-0">{errors.upload_proposal}</p>
                ) : null}
              </Col>
            </Row>

            <div className="text-start mb-2">
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox required />}
                  label={t("I Agree To All the Terms and Conditions")}
                />
              </FormGroup>

              <Button
                variant="contained"
                className="text-center my-1 btn btn-info"
              >
                <Link
                  onClick={handleShow}
                  className="text-decoration-none text-white"
                >
                  View Terms and Conditions
                </Link>
              </Button>
            </div>

            <Button
              variant="contained"
              type="submit"
              className="button px-3 text-center my-1 btn btn-info"
              disabled={processing}
            >
              {processing ? <Loader /> : t("Submit")}
            </Button>

            <div className=" my-2">
              {processing && (
                <p className="fw-bold">
                  Submitting your proposal it will take some time do not refresh
                  or close the page...
                </p>
              )}
            </div>
          </form>
        </Col>
      </div>

      <TermsAndConditions handleClose={handleClose} show={show} />
    </div>
  );
};

export default IndividualForm;
