import { Button } from "@mui/material";
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const GuideLines = () => {
  const [formType, setFormType] = useState("guidlines");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Check if the screen size is mobile
  const { t } = useTranslation();

  return (
    <div
      className="bg-white p-4 my-2"
      style={{
        height: isMobile ? "auto" : "50rem", // Adjust height for mobile
        overflowY: isMobile ? "visible" : "scroll",
        padding: isMobile ? "10px" : "20px",
      }}
    >
      <div className="text-center">
        <Button
          className="mx-2 mb-3"
          sx={{
            backgroundColor: "#e18d20",
            color: "white",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            padding: "10px 20px",
            width: isMobile ? "100%" : "auto", // Full-width on mobile
          }}
        >
          {t("Submission Guidelines")}
        </Button>

        <Button
          className="mx-2 mb-3"
          sx={{
            backgroundColor: "#e18d20",
            color: "white",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            padding: "10px 20px",
            width: isMobile ? "100%" : "auto", // Full-width on mobile
          }}
        >
          <NavLink to="/download" className="text-decoration-none text-white">
            {" "}
            {t("Download")}
          </NavLink>
        </Button>
      </div>

      <table
        className="table table-bordered table-striped"
        // style={{ width: "100%" }}
      >
        <tbody>
          <tr>
            <td className="p-3">1</td>
            <td className="p-3">
              {t(
                "Any form of presentation, file size (up to 100 MB) and format (PDF/JPG/PNG/PPT/DOC/ZIP) is acceptable. However, we recommend the proposals be composed on landscape-oriented A1 size sheets."
              )}
            </td>
          </tr>
          <tr>
            <td className="p-3">2</td>
            <td className="p-3">
              {t(
                "Any style of presentation and tool used to convey the idea is acceptable – images, 3D models, pictures, sketches, etc."
              )}
            </td>
          </tr>
          <tr>
            <td className="p-3">3</td>
            <td className="p-3">
              {t("A 500-word proposal summary should also be submitted.")}
            </td>
          </tr>
          <tr>
            <td className="p-3">4</td>
            <td className="p-3">
              {t("There is no entry fee for participation in the competition.")}
            </td>
          </tr>
          <tr>
            <td className="p-3">5</td>
            <td className="p-3">
              {t(
                "The stretch is divided into 6 focus areas, and participants can opt to submit solutions for one section, part of one section, multiple sections, or the entire stretch from Timbakeshwar to Nashik in a single proposal."
              )}
            </td>
          </tr>
          <tr>
            <td className="p-3">6</td>
            <td className="p-3">
              {t(
                "Entries would be judged based on elements of creativity, technical excellence, visual impact, and how well they communicate the theme."
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default GuideLines;
