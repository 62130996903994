// import React, { useState, useEffect } from "react";
// import { server, truncateText } from "../common";
// import { useSelectAccess } from "../store/stateFunctions";
// import { toast } from "react-toastify";
// import { AxiosError } from "axios";
// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";
// import ReactHTMLTableToExcel from "react-html-table-to-excel";
// import { Modal } from "react-bootstrap";
// import { Button } from "@mui/material";

// const swalalert = withReactContent(Swal);

// const IndividualTotalApplications = () => {
//   const [data, setData] = useState([]);
//   const user = useSelectAccess("Super Admin");
//   const [selectedId, setSelectedId] = useState(null);
//   const [applicationNumber, setApplicationNumber] = useState("");
//   const [totalPages, setTotalPages] = useState(1);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [fromDate, setFromDate] = useState(""); // From date
//   const [toDate, setToDate] = useState(""); // To date
//   const [showModal, setShowModal] = useState(false);
//   const [selectedProposal, setSelectedProposal] = useState([]); // State to hold the selected proposal images
//   const [selectedUser, setSelectedUser] = useState(null); // State to hold selected user details
//   const [showdescModal, setShowdescModal] = useState(false);

//   const handleClose = () => setShowModal(false);
//   const handleShow = (proposal) => {
//     setSelectedProposal(proposal); // Set the proposal images to display in modal
//     setShowModal(true);
//   };

//   const handleCloseDesc = () => {
//     setShowdescModal(false);
//   };

//   const handleShowDesc = (descr) => {
//     setSelectedUser(descr);
//     setShowdescModal(true);
//   };

//   useEffect(() => {
//     getData(currentPage); // Initial data fetch
//   }, [currentPage]);

//   const handlePageChange = (page) => {
//     if (page > 0 && page <= totalPages) {
//       setCurrentPage(page);
//     }
//   };

//   // Get all data with pagination
//   const getData = (page) => {
//     server
//       .get(`/individual/pagination?page=${page}&limit=50`, {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: user.authToken,
//         },
//       })
//       .then((response) => {
//         console.log("API Response:", response.data);
//         if (response.status === 200 || response.status === 201) {
//           setData(response.data.userApplications); // Set the data array correctly
//           setTotalPages(response.data.paginationInfo.totalPages); // Set the total number of pages correctly
//         }
//       })
//       .catch((error) => {
//         if (error instanceof AxiosError && error.response?.data?.message) {
//           toast.error(error.response.data.message);
//         } else {
//           toast.error(
//             error.response?.data?.error || "Failed to connect to server"
//           );
//         }
//       });
//   };

//   // Delete gallery
//   const deleteInternShip = (data) => {
//     swalalert
//       .fire({
//         title: "Delete Confirmation!",
//         text: `Are You Sure That You Want To Delete This Application ${data.id} Permanently ?`,
//         type: "warning",
//         showCancelButton: true,
//         confirmButtonColor: "#3085d6",
//         cancelButtonColor: "#d33",
//         confirmButtonText: "Yes",
//         cancelButtonText: "No",
//         confirmButtonClass: "btn btn-success",
//         cancelButtonClass: "btn btn-danger",
//         buttonsStyling: true,
//       })
//       .then(function (swalObject) {
//         if (swalObject.isConfirmed) {
//           server
//             .delete(`/individual/deleteindividual/${data.id}`, {
//               headers: {
//                 "Content-Type": "application/json",
//                 // Authorization: user.authToken,
//               },
//             })
//             .then(function (response) {
//               console.log("api response", response.data);
//               if (response.status === 200 || response.status === 201) {
//                 swalalert.fire(
//                   "Deleted!",
//                   "Contact entry has been deleted.",
//                   "success"
//                 );
//                 getData();
//               }
//             })
//             .catch((error) => {
//               toast.error(error.response.data.message);
//             });
//         } else {
//           swalalert.fire(
//             "Cancelled",
//             "Your imaginary file is safe :)",
//             "error"
//           );
//         }
//         //success method
//       });
//   };

//   // search
//   const SearchData = (searchValue) => {
//     server
//       .get(`/individual/searchnallbyid?search=${searchValue}`, {
//         headers: {
//           "Content-Type": "application/json",
//           // Authorization: user.authToken,
//         },
//       })
//       .then(function (response) {
//         console.log("api response", response.data);
//         if (response.status === 200 || response.status === 201) {
//           setData(response.data);
//         }
//       })
//       .catch(function (error) {
//         if (error instanceof AxiosError && error.response?.data?.message)
//           toast.error(error.response.data.message);
//         else if (error.response?.data?.error) {
//           toast.error(error.response.data.error);
//         } else toast.error("Failed to connect to server");
//       });
//   };

//   // Filter by date
//   const filterByDate = () => {
//     if (!fromDate || !toDate) {
//       toast.error("Please select both from and to dates");
//       return;
//     }
//     server
//       .get(`/individual/searchByDate?startDate=${fromDate}&endDate=${toDate}`, {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: user.authToken,
//         },
//       })
//       .then((response) => {
//         if (response.status === 200 || response.status === 201) {
//           setData(response.data);
//         }
//       })
//       .catch((error) => {
//         if (error instanceof AxiosError && error.response?.data?.message) {
//           toast.error(error.response.data.message);
//         } else {
//           toast.error(
//             error.response?.data?.error || "Failed to connect to server"
//           );
//         }
//       });
//   };

//   //   // search filter wise
//   const FilterData = (event) => {
//     const selectedValue = event.target.value;
//     server
//       .get(`/individual/searchindividual?occupation=${selectedValue}`, {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: user.authToken,
//         },
//       })
//       .then(function (response) {
//         console.log("api response", response.data);
//         if (response.status === 200 || response.status === 201) {
//           setData(response.data);
//         }
//       })
//       .catch(function (error) {
//         if (error instanceof AxiosError && error.response?.data?.message)
//           toast.error(error.response.data.message);
//         else if (error.response?.data?.error) {
//           toast.error(error.response.data.error);
//         } else toast.error("Failed to connect to server");
//       });
//   };

//   return (
//     <>
//       <div className="p-2 ">
//         {/* Search Form Section */}
//         <h3 className="text-center py-2">
//           Total Applications of Individual Applicants
//         </h3>

//         <div className="card mb-3">
//           <div className="card-header">Search Application</div>
//           <div className="card-body row">
//             {/* Proposal Search Input */}
//             <div className="form-group col-12 col-md-3">
//               <label>Search by Name/Email/Number</label>
//               <input
//                 type="search"
//                 className="form-control"
//                 placeholder="Search"
//                 onChange={(e) => SearchData(e.target.value)}
//               />
//             </div>

//             <div className="col-md-4">
//               <label>Search by Occupation</label>

//               <select
//                 className="form-control"
//                 // name=""
//                 // id=""
//                 onChange={(e) => FilterData(e)}
//               >
//                 <option value="Student" defaultValue>
//                   Student
//                 </option>
//                 <option value="Professional" defaultValue>
//                   Professional
//                 </option>
//                 <option value="Citizen">Citizen</option>
//               </select>
//             </div>
//             {/* From Date */}
//             <div className="form-group col-12 col-md-2">
//               <label>From Date</label>
//               <input
//                 type="date"
//                 className="form-control"
//                 value={fromDate}
//                 onChange={(e) => setFromDate(e.target.value)}
//               />
//             </div>

//             {/* To Date */}
//             <div className="form-group col-12 col-md-2">
//               <label>To Date</label>
//               <input
//                 type="date"
//                 className="form-control"
//                 value={toDate}
//                 onChange={(e) => setToDate(e.target.value)}
//               />
//             </div>

//             {/* Filter Button */}
//             <div className="form-group col-12 col-md-1 d-flex align-items-end justify-content-center">
//               <button
//                 className="btn btn-info w-100 text-center"
//                 onClick={filterByDate}
//               >
//                 Filter
//               </button>
//             </div>
//           </div>
//         </div>
//         {/* Export to Excel Button */}
//         <div className="text-end">
//           <ReactHTMLTableToExcel
//             className="button btn btn-warning"
//             id="test-table-xls-button"
//             table="totalapplications"
//             filename={`Total Applications Report ${new Date()}`}
//             sheet="Garden Report"
//             buttonText="Export To Excel"
//           />
//         </div>
//         {/* Table Section */}
//         <div className="mt-4">
//           <div className="card mb-3">
//             <div className="card-header">Total Application</div>
//             <div className="card-body">
//               <div className="table-responsive">
//                 <table
//                   className="table table-striped table-bordered text-center"
//                   id="totalapplications"
//                 >
//                   <thead className="thead-dark">
//                     <tr>
//                       <th>Sr.No.</th>
//                       {/* <th>Application No.</th> */}
//                       <th>Date</th>
//                       <th>Fullname</th>
//                       <th>Email</th>
//                       <th>Mobile</th>
//                       <th>Occupation</th>
//                       <th>Short Description</th>
//                       <th>View</th>
//                       {/* <th>Delete</th> */}
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {data.length > 0 ? (
//                       data.map((item, index) => (
//                         <tr key={item.id}>
//                           <td>{(currentPage - 1) * 10 + (index + 1)}</td>
//                           {/* <td>{item.id}</td> */}
//                           <td>
//                             {/* {
//                               new Date(item.created_at)
//                                 .toISOString()
//                                 .split("T")[0]
//                             } */}
//                           </td>
//                           <td>{item.fullname}</td>
//                           <td>{item.email}</td>
//                           <td>{item.mobile}</td>
//                           <td>{item.occupation}</td>
//                           <td
//                             style={{ textAlign: "justify" }}
//                             onClick={() => handleShowDesc(item)}
//                           >
//                             {truncateText(item.short_idea, 15)}
//                             <br />
//                             <a className="text-primary">Read More</a>{" "}
//                           </td>
//                           <td>
//                             <button
//                               className="btn btn-sm btn-primary"
//                               onClick={() => handleShow(item.upload_proposal)} // Pass the proposal array to handleShow
//                             >
//                               View
//                             </button>
//                           </td>
//                           {/* <td>
//                             <div
//                               className="btn btn-danger btn-sm"
//                               onClick={() => deleteInternShip(item)}
//                             >
//                               Delete
//                             </div>
//                           </td> */}
//                         </tr>
//                       ))
//                     ) : (
//                       <tr>
//                         <td colSpan="10">No Applications Found</td>
//                       </tr>
//                     )}
//                   </tbody>
//                 </table>
//               </div>
//             </div>
//           </div>

//           {/* Pagination Section */}
//           <nav aria-label="Page navigation example">
//             <ul className="pagination justify-content-center">
//               <li
//                 className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
//               >
//                 <button
//                   className="page-link"
//                   onClick={() => handlePageChange(currentPage - 1)}
//                 >
//                   Previous
//                 </button>
//               </li>

//               {/* Dynamically generated pagination links */}
//               {Array.from(
//                 { length: Math.min(10, totalPages) }, // Show up to 10 pages at a time
//                 (_, index) => {
//                   const startPage = Math.max(1, currentPage - 5); // Dynamically calculate start page
//                   const page = startPage + index;

//                   if (page <= totalPages) {
//                     return (
//                       <li
//                         key={page}
//                         className={`page-item ${
//                           currentPage === page ? "active" : ""
//                         }`}
//                       >
//                         <button
//                           className="page-link"
//                           onClick={() => handlePageChange(page)}
//                         >
//                           {page}
//                         </button>
//                       </li>
//                     );
//                   }
//                   return null;
//                 }
//               )}

//               <li
//                 className={`page-item ${
//                   currentPage === totalPages ? "disabled" : ""
//                 }`}
//               >
//                 <button
//                   className="page-link"
//                   onClick={() => handlePageChange(currentPage + 1)}
//                 >
//                   Next
//                 </button>
//               </li>
//             </ul>
//           </nav>
//         </div>
//       </div>

//       {/* Modal to display images */}
//       <Modal show={showModal} onHide={handleClose} size="lg">
//         <Modal.Header closeButton>
//           <Modal.Title>Proposal Images</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           {selectedProposal.map((image, index) => {
//             const isPDF = image.url.toLowerCase().endsWith(".pdf");
//             const isImage = /\.(jpg|jpeg|png|gif)$/i.test(image.url);

//             return (
//               <div key={index} className="mb-3">
//                 {isPDF ? (
//                   <iframe
//                     src={image.url}
//                     title={`Proposal PDF ${index + 1}`}
//                     style={{ width: "100%", height: "500px" }}
//                   ></iframe>
//                 ) : isImage ? (
//                   <img
//                     src={image.url}
//                     alt={`Proposal Image ${index + 1}`}
//                     className="img-fluid"
//                     style={{ maxHeight: "100%", maxWidth: "100%" }}
//                   />
//                 ) : (
//                   <Button variant="primary" href={image.url} download>
//                     Download File
//                   </Button>
//                 )}
//               </div>
//             );
//           })}
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleClose}>
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>

//       <Modal show={showdescModal} onHide={handleCloseDesc} size="lg">
//         <Modal.Header closeButton>
//           <Modal.Title>User Details</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           {selectedUser ? (
//             <div>
//               <p>
//                 <strong>Description:</strong> {selectedUser.short_idea}
//               </p>
//             </div>
//           ) : (
//             <p>No details available</p>
//           )}
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleCloseDesc}>
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// };

// export default IndividualTotalApplications;

import React, { useState, useEffect } from "react";
import { server, truncateText } from "../common";
import { useSelectAccess } from "../store/stateFunctions";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { Modal } from "react-bootstrap";
import { Button } from "@mui/material";
import { AxiosError } from "axios";

const swalalert = withReactContent(Swal);

const IndividualTotalApplications = () => {
  const [data, setData] = useState([]);
  const user = useSelectAccess("Super Admin");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [showDocumentModal, setShowDocumentModal] = useState(false); // Modal to show document options
  const [showViewModal, setShowViewModal] = useState(false); // Modal to view the selected document
  const [selectedProposal, setSelectedProposal] = useState([]); // State for proposal documents
  const [selectedDocument, setSelectedDocument] = useState(""); // State for the selected document to view
  const [documentType, setDocumentType] = useState(""); // State to handle the type of document
  const [fromDate, setFromDate] = useState(""); // From date
  const [toDate, setToDate] = useState("");
  const [showdescModal, setShowdescModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null); // State to hold selected user details

  const handleshowdescModal = (desc) => {
    setSelectedUser(desc);
    setShowdescModal(true);
  };

  const handledescClose = () => {
    setShowdescModal(false);
  };

  // Function to open the document selection modal
  const handleShowDocuments = (proposal) => {
    setSelectedProposal(proposal); // Set the documents to show
    setShowDocumentModal(true); // Open the modal
  };

  // Function to handle document selection
  const handleViewDocument = (docUrl) => {
    const fileExtension = docUrl.split(".").pop().toLowerCase(); // Get the file extension

    // Determine the type of document
    if (fileExtension === "pdf") {
      setDocumentType("pdf");
    } else if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
      setDocumentType("image");
    } else {
      setDocumentType("other");
    }

    setSelectedDocument(docUrl); // Set the selected document to view
    // setShowDocumentModal(false); // Close the document options modal
    setShowViewModal(true); // Open the modal to show the document
  };

  // Close both modals
  const handleCloseDocumentModal = () => setShowDocumentModal(false);
  const handleCloseViewModal = () => setShowViewModal(false);

  useEffect(() => {
    getData(currentPage); // Fetch initial data
  }, [currentPage]);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // Fetch data function
  const getData = (page) => {
    server
      .get(`/individual/pagination?page=${page}&limit=50`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setData(response.data.userApplications); // Set data
          setTotalPages(response.data.paginationInfo.totalPages); // Set pagination info
        }
      })
      .catch((error) => {
        toast.error("Failed to connect to server");
      });
  };

  //   // search
  const SearchData = (searchValue) => {
    server
      .get(`/individual/searchnallbyid?search=${searchValue}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then(function (response) {
        console.log("api response", response.data);
        if (response.status === 200 || response.status === 201) {
          setData(response.data);
        }
      })
      .catch(function (error) {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else if (error.response?.data?.error) {
          toast.error(error.response.data.error);
        } else toast.error("Failed to connect to server");
      });
  };

  //   // Filter by date
  const filterByDate = () => {
    if (!fromDate || !toDate) {
      toast.error("Please select both from and to dates");
      return;
    }
    server
      .get(`/individual/searchByDate?startDate=${fromDate}&endDate=${toDate}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setData(response.data);
        }
      })
      .catch((error) => {
        if (error instanceof AxiosError && error.response?.data?.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error(
            error.response?.data?.error || "Failed to connect to server"
          );
        }
      });
  };

  //   // search filter wise
  const FilterData = (event) => {
    const selectedValue = event.target.value;
    server
      .get(`/individual/searchindividual?occupation=${selectedValue}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then(function (response) {
        console.log("api response", response.data);
        if (response.status === 200 || response.status === 201) {
          setData(response.data);
        }
      })
      .catch(function (error) {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else if (error.response?.data?.error) {
          toast.error(error.response.data.error);
        } else toast.error("Failed to connect to server");
      });
  };

  return (
    <>
      <div className="card mb-3">
        <div className="card-header">Search Application</div>
        <div className="card-body row">
          {/* Proposal Search Input */}
          <div className="form-group col-12 col-md-3">
            <label>Search by Name/Email/Number</label>
            <input
              type="search"
              className="form-control"
              placeholder="Search"
              onChange={(e) => SearchData(e.target.value)}
            />
          </div>

          <div className="col-md-4">
            <label>Search by Occupation</label>

            <select
              className="form-control"
              // name=""
              // id=""
              onChange={(e) => FilterData(e)}
            >
              {" "}
              <option value="All" defaultValue>
                All
              </option>
              <option value="Student" defaultValue>
                Student
              </option>
              <option value="Professional" defaultValue>
                Professional
              </option>
              <option value="Citizen">Citizen</option>
            </select>
          </div>
          {/* From Date */}
          <div className="form-group col-12 col-md-2">
            <label>From Date</label>
            <input
              type="date"
              className="form-control"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
          </div>

          {/* To Date */}
          <div className="form-group col-12 col-md-2">
            <label>To Date</label>
            <input
              type="date"
              className="form-control"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
          </div>

          {/* Filter Button */}
          <div className="form-group col-12 col-md-1 d-flex align-items-end justify-content-center">
            <button
              className="btn btn-info w-100 text-center"
              onClick={filterByDate}
            >
              Filter
            </button>
          </div>
        </div>
      </div>
      {/* Modal to display document options */}
      <Modal show={showDocumentModal} onHide={handleCloseDocumentModal}>
        <Modal.Header closeButton>
          <Modal.Title>View All Documents</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedProposal && selectedProposal.length > 0 ? (
            selectedProposal.map((doc, index) => (
              <>
                <table className="table table-bordered text-center">
                  <tbody>
                    <tr>
                      <td>{index + 1}</td>
                      <td>
                        <Button
                          key={index}
                          variant="contained"
                          onClick={() => handleViewDocument(doc.url)}
                        >
                          View
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            ))
          ) : (
            <p>No documents available.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="contained" onClick={handleCloseDocumentModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal to display the selected document */}
      <Modal show={showViewModal} onHide={handleCloseViewModal}>
        <Modal.Header closeButton>
          <Modal.Title>View Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedDocument ? (
            <>
              {documentType === "pdf" && (
                <iframe
                  src={selectedDocument}
                  title="Document Viewer"
                  width="100%"
                  height="500px"
                  style={{ border: "none" }}
                ></iframe>
              )}
              {documentType === "image" && (
                <img
                  src={selectedDocument}
                  alt="Selected Document"
                  style={{ width: "100%" }}
                />
              )}
              {documentType === "other" && (
                <>
                  <p> Download this file and use neccesary tool to view file</p>
                  <a
                    href={selectedDocument}
                    download
                    className="btn btn-primary"
                  >
                    Download Document
                  </a>
                </>
              )}
            </>
          ) : (
            <p>No document selected.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="contained" onClick={handleCloseViewModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal to display user details */}
      <Modal show={showdescModal} onHide={handledescClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Short Description</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedUser ? (
            <div>
              <p>
                <strong>Description:</strong> {selectedUser.short_idea}
              </p>
            </div>
          ) : (
            <p>No details available</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handledescClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="table-responsive">
        <table
          className="table table-striped table-bordered"
          id="totalapplications"
        >
          <thead className="thead-dark">
            <tr>
              <th>Sr.No.</th>
              <th>Date</th>
              <th>Fullname</th>
              <th>Email</th>
              <th>Mobile</th>
              <th>Occupation</th>
              <th>Short Description</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((item, index) => (
                <tr key={item.id}>
                  <td>{(currentPage - 1) * 10 + (index + 1)}</td>
                  <td>{item.created_at}</td>
                  <td>{item.fullname}</td>
                  <td>{item.email}</td>
                  <td>{item.mobile}</td>
                  <td>{item.occupation}</td>
                  <td onClick={() => handleshowdescModal(item)}>
                    {truncateText(item.short_idea, 15)}
                    <br />
                    <a className="text-primary" style={{ cursor: "pointer" }}>
                      Read More
                    </a>
                  </td>
                  <td>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => handleShowDocuments(item.upload_proposal)} // Pass proposal to document modal
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8">No Applications Found</td>
              </tr>
            )}
          </tbody>
        </table>

        {/* Pagination Section */}
        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-center">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </button>
            </li>

            {Array.from({ length: Math.min(10, totalPages) }, (_, index) => {
              const startPage = Math.max(1, currentPage - 5);
              const page = startPage + index;
              return page <= totalPages ? (
                <li
                  key={page}
                  className={`page-item ${
                    currentPage === page ? "active" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(page)}
                  >
                    {page}
                  </button>
                </li>
              ) : null;
            })}

            <li
              className={`page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default IndividualTotalApplications;

// import React, { useState, useEffect } from "react";
// import { server, truncateText } from "../common";
// import { useSelectAccess } from "../store/stateFunctions";
// import { toast } from "react-toastify";
// import { AxiosError } from "axios";
// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";
// import { Modal } from "react-bootstrap";
// import { Button } from "@mui/material";
// import { MdDelete } from "react-icons/md";
// import ReactHTMLTableToExcel from "react-html-table-to-excel";

// const swalalert = withReactContent(Swal);

// const IndividualTotalApplications = () => {
//   const [data, setData] = useState([]);
//   const user = useSelectAccess("Super Admin");
//   const [selectedUser, setSelectedUser] = useState(null); // State to hold selected user details
//   const [showModal, setShowModal] = useState(false); // Modal state for user details
//   const [totalPages, setTotalPages] = useState(1);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [fromDate, setFromDate] = useState(""); // From date
//   const [toDate, setToDate] = useState(""); // To date

//   const handleClose = () => setShowModal(false);

//   const handleShow = (user) => {
//     setSelectedUser(user); // Set the selected user data to display in modal
//     setShowModal(true);
//   };

//   useEffect(() => {
//     getData(currentPage); // Initial data fetch
//   }, [currentPage]);

//   const handlePageChange = (page) => {
//     if (page > 0 && page <= totalPages) {
//       setCurrentPage(page);
//     }
//   };

//   // Get all data with pagination
//   const getData = (page) => {
//     server
//       .get(`/individual/pagination?page=${page}&limit=50`, {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: user.authToken,
//         },
//       })
//       .then((response) => {
//         if (response.status === 200 || response.status === 201) {
//           setData(response.data.userApplications); // Set the data array correctly
//           setTotalPages(response.data.paginationInfo.totalPages); // Set the total number of pages correctly
//         }
//       })
//       .catch((error) => {
//         if (error instanceof AxiosError && error.response?.data?.message) {
//           toast.error(error.response.data.message);
//         } else {
//           toast.error(
//             error.response?.data?.error || "Failed to connect to server"
//           );
//         }
//       });
//   };

//   // Delete function for an entry
//   const deleteEntry = (user) => {
//     swalalert
//       .fire({
//         title: "Delete Confirmation!",
//         text: `Are you sure you want to delete Application ${user.id}?`,
//         type: "warning",
//         showCancelButton: true,
//         confirmButtonColor: "#3085d6",
//         cancelButtonColor: "#d33",
//         confirmButtonText: "Yes",
//         cancelButtonText: "No",
//         confirmButtonClass: "btn btn-success",
//         cancelButtonClass: "btn btn-danger",
//         buttonsStyling: true,
//       })
//       .then((swalObject) => {
//         if (swalObject.isConfirmed) {
//           server
//             .delete(`/individual/deleteindividual/${user.id}`, {
//               headers: {
//                 "Content-Type": "application/json",
//                 Authorization: user.authToken,
//               },
//             })
//             .then((response) => {
//               if (response.status === 200 || response.status === 201) {
//                 swalalert.fire(
//                   "Deleted!",
//                   "The application has been deleted.",
//                   "success"
//                 );
//                 getData(currentPage); // Refresh data
//               }
//             })
//             .catch((error) => {
//               toast.error(error.response.data.message);
//             });
//         }
//       });
//   };

//   // search
//   const SearchData = (searchValue) => {
//     server
//       .get(`/individual/searchnallbyid?search=${searchValue}`, {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: user.authToken,
//         },
//       })
//       .then(function (response) {
//         console.log("api response", response.data);
//         if (response.status === 200 || response.status === 201) {
//           setData(response.data);
//         }
//       })
//       .catch(function (error) {
//         if (error instanceof AxiosError && error.response?.data?.message)
//           toast.error(error.response.data.message);
//         else if (error.response?.data?.error) {
//           toast.error(error.response.data.error);
//         } else toast.error("Failed to connect to server");
//       });
//   };

//   // Filter by date
//   const filterByDate = () => {
//     if (!fromDate || !toDate) {
//       toast.error("Please select both from and to dates");
//       return;
//     }
//     server
//       .get(`/individual/searchByDate?startDate=${fromDate}&endDate=${toDate}`, {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: user.authToken,
//         },
//       })
//       .then((response) => {
//         if (response.status === 200 || response.status === 201) {
//           setData(response.data);
//         }
//       })
//       .catch((error) => {
//         if (error instanceof AxiosError && error.response?.data?.message) {
//           toast.error(error.response.data.message);
//         } else {
//           toast.error(
//             error.response?.data?.error || "Failed to connect to server"
//           );
//         }
//       });
//   };

//   // search filter wise
//   const FilterData = (event) => {
//     const selectedValue = event.target.value;
//     server
//       .get(`/individual/searchindividual?occupation=${selectedValue}`, {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: user.authToken,
//         },
//       })
//       .then(function (response) {
//         console.log("api response", response.data);
//         if (response.status === 200 || response.status === 201) {
//           setData(response.data);
//         }
//       })
//       .catch(function (error) {
//         if (error instanceof AxiosError && error.response?.data?.message)
//           toast.error(error.response.data.message);
//         else if (error.response?.data?.error) {
//           toast.error(error.response.data.error);
//         } else toast.error("Failed to connect to server");
//       });
//   };

//   return (
//     <>
//       <div className="p-2">
//         <h3 className="text-center py-2">Total Applications</h3>

//         <div className="card mb-3">
//           <div className="card-header">Search Application</div>
//           <div className="card-body row">
//             {/* Proposal Search Input */}
//             <div className="form-group col-12 col-md-3">
//               <label>Search by Name/Email/Number</label>
//               <input
//                 type="search"
//                 className="form-control"
//                 placeholder="Search"
//                 onChange={(e) => SearchData(e.target.value)}
//               />
//             </div>

//             <div className="col-md-4">
//               <label>Search by Occupation</label>

//               <select
//                 className="form-control"
//                 // name=""
//                 // id=""
//                 onChange={(e) => FilterData(e)}
//               >
//                 <option value="Student" defaultValue>
//                   Student
//                 </option>
//                 <option value="Professional" defaultValue>
//                   Professional
//                 </option>
//                 <option value="Citizen">Citizen</option>
//               </select>
//             </div>
//             {/* From Date */}
//             <div className="form-group col-12 col-md-2">
//               <label>From Date</label>
//               <input
//                 type="date"
//                 className="form-control"
//                 value={fromDate}
//                 onChange={(e) => setFromDate(e.target.value)}
//               />
//             </div>

//             {/* To Date */}
//             <div className="form-group col-12 col-md-2">
//               <label>To Date</label>
//               <input
//                 type="date"
//                 className="form-control"
//                 value={toDate}
//                 onChange={(e) => setToDate(e.target.value)}
//               />
//             </div>

//             {/* Filter Button */}
//             <div className="form-group col-12 col-md-1 d-flex align-items-end justify-content-center">
//               <button
//                 className="btn btn-info w-100 text-center"
//                 onClick={filterByDate}
//               >
//                 Filter
//               </button>
//             </div>
//           </div>
//         </div>
//         {/* Export to Excel Button */}
//         <div className="text-end">
//           <ReactHTMLTableToExcel
//             className="button btn btn-warning"
//             id="test-table-xls-button"
//             table="totalapplications"
//             filename={`Total Applications Report ${new Date()}`}
//             sheet="Garden Report"
//             buttonText="Export To Excel"
//           />
//         </div>

//         {/* Table Section */}
//         <div className="mt-4">
//           <div className="card mb-3">
//             <div className="card-header">Total Applications</div>
//             <div className="card-body">
//               <div className="table-responsive">
//                 <table
//                   className="table table-striped table-bordered"
//                   id="totalapplications"
//                 >
//                   <thead className="thead-dark">
//                     <tr>
//                       <th>Sr.No.</th>
//                       {/* <th>Application ID</th> */}
//                       <th>Fullname</th>
//                       <th>Email</th>
//                       <th>Mobile</th>
//                       <th>Occupation</th>
//                       <th>Description</th>
//                       {/* <th>View</th> */}
//                       <th>View Document</th> {/* New column for images */}
//                       {/* <th>Delete</th> */}
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {data ? (
//                       data.map((item, index) => (
//                         <tr key={item.id}>
//                           <td>{(currentPage - 1) * 10 + (index + 1)}</td>
//                           {/* <td>{item.id}</td> */}
//                           <td>{item.fullname}</td>
//                           <td>{item.email}</td>
//                           <td>{item.mobile}</td>
//                           <td>{item.occupation}</td>
//                           <td
//                             style={{ textAlign: "justify" }}
//                             onClick={() => handleShow(item)}
//                           >
//                             {truncateText(item.short_idea, 15)}
//                             <br />
//                             <a className="text-primary">Read More</a>
//                           </td>
//                           {/* <td>
//                             <Button
//                               variant="contained"
//                               color="primary"
//                               onClick={() => handleShow(item)} // Pass the user data to handleShow
//                             >
//                               View
//                             </Button>
//                           </td> */}
//                           <td>
//                             {/* Display buttons for each image in the upload_proposal array */}
//                             {item.upload_proposal &&
//                             item.upload_proposal.length > 0 ? (
//                               item.upload_proposal.map((image, imgIndex) => (
//                                 <>
//                                   <a
//                                     key={imgIndex}
//                                     href={image.url}
//                                     target="_blank"
//                                     rel="noopener noreferrer"
//                                     // className="text-decoration-none m-1"
//                                     className="btn btn-info m-1 btn-sm"
//                                   >
//                                     View Document {imgIndex + 1}
//                                   </a>
//                                   <br />
//                                 </>
//                               ))
//                             ) : (
//                               <span>No Images</span>
//                             )}
//                           </td>

//                           {/* <td>
//                             <Button
//                               variant="contained"
//                               color="error"
//                               onClick={() => deleteEntry(item)}
//                               size="sm"
//                             >
//                               <MdDelete />
//                             </Button>
//                           </td> */}
//                         </tr>
//                       ))
//                     ) : (
//                       <tr>
//                         <td colSpan="6">No Applications Found</td>
//                       </tr>
//                     )}
//                   </tbody>
//                 </table>
//               </div>
//             </div>
//           </div>

//           {/* Pagination Section */}
//           <nav aria-label="Page navigation example">
//             <ul className="pagination justify-content-center">
//               <li
//                 className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
//               >
//                 <button
//                   className="page-link"
//                   onClick={() => handlePageChange(currentPage - 1)}
//                 >
//                   Previous
//                 </button>
//               </li>

//               {Array.from({ length: Math.min(10, totalPages) }, (_, index) => {
//                 const startPage = Math.max(1, currentPage - 5);
//                 const page = startPage + index;
//                 return page <= totalPages ? (
//                   <li
//                     key={page}
//                     className={`page-item ${
//                       currentPage === page ? "active" : ""
//                     }`}
//                   >
//                     <button
//                       className="page-link"
//                       onClick={() => handlePageChange(page)}
//                     >
//                       {page}
//                     </button>
//                   </li>
//                 ) : null;
//               })}

//               <li
//                 className={`page-item ${
//                   currentPage === totalPages ? "disabled" : ""
//                 }`}
//               >
//                 <button
//                   className="page-link"
//                   onClick={() => handlePageChange(currentPage + 1)}
//                 >
//                   Next
//                 </button>
//               </li>
//             </ul>
//           </nav>
//         </div>
//       </div>

//       {/* Modal to display user details */}
//       <Modal show={showModal} onHide={handleClose} size="lg">
//         <Modal.Header closeButton>
//           <Modal.Title>User Details</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           {selectedUser ? (
//             <div>
//               <p>
//                 <strong>Description:</strong> {selectedUser.short_idea}
//               </p>
//             </div>
//           ) : (
//             <p>No details available</p>
//           )}
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleClose}>
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// };

// export default IndividualTotalApplications;
