import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./announcement.css";

const Announcement = () => {
  return (
    <div className="">
      <Row>
        <Col>
          <div className="announcement">
            <h5 className="self-align-center fw-bold mt-2 p-1 text-dark">
              Announcement
            </h5>
            <img src="/news.gif" alt="Announcement GIF" className="gif" />
            <marquee
              behavior="scroll"
              direction="left"
              scrollamount="10"
              className="fs-4"
            >
              The session dates for the Godavari Riverfront Development Design
              Competition have been updated: &nbsp;
              <span className="text-dark fs-5">
                Session 1 - 11 December 2024 || Session 2 - 08 January 2025.
              </span>
              &nbsp; Link for both sessions -{" "}
              <a
                href="https://meet.google.com/utb-xhkt-dkc"
                className="text-decoration-none fs-5"
              >
                https://meet.google.com/utb-xhkt-dkc
              </a>
            </marquee>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Announcement;
